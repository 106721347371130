import { observable, action } from "mobx";
import { RootStore } from "./store";

export interface PauseItems {
    dish_ids: string[];
    duration?: number;
    pause_start?: number;
    pause_end?: number;
	pause_infinity?: boolean;
    reason?: string;
	status?:string;
}

export interface AblyState  {
  pausedServices: T.Schema.Restaurant.AblyPauseServiceResponseData  | null;
  pausedItems: PauseItems | null;
  restaurantSettings: T.ObjectAny | null;
}

export class AblyStore {
  @observable s: AblyState;
	store: RootStore;

	constructor(store: RootStore, initialState?: AblyState) {
		this.store = store;
		this.s = {
      pausedServices: null,
      pausedItems: null,
      restaurantSettings: null,
			...(initialState || {}),
		};
	}

    @action update(data: Partial<AblyState>) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof AblyState];
				if (value !== undefined && this.s) {
					// @ts-ignore
					this.s[key as keyof AblyState] = value;
				}
			}
		}
	}
}
