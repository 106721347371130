// @ts-ignore
import crypto from 'crypto-browserify';
import { config } from '../../config';

export function decryptData(encryptedData: string) {
  const key = crypto.createHash("sha256").update(config.secret_key).digest("base64").substr(0, 32);
  const [ivHex, encryptedText] = encryptedData.split(":");
  const iv = Buffer.from(ivHex, "hex");

  const decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
  let decrypted = decipher.update(Buffer.from(encryptedText, "hex"));
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return JSON.parse(decrypted.toString());
}