import * as React from "react";
import { observer, inject } from "mobx-react";
import { styled, Select, CS, shadeAlternate } from "@lib/components";
import { action } from "mobx";
import { MobxComponent } from "../../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {
	className?: string;
}
interface State { }

const Wrapper = styled("div")`
  ${CS.flex.center};
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => shadeAlternate(0.15, theme.top_nav.background_color)};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: auto;
    border-bottom: none;
  }
`;

const SelectSVGArrow = `data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>`;

const SelectInput = styled(Select)`
  font-size: 14px;
  background: ${({ theme }) => theme.top_nav.background_color};
  color: ${({ theme }) => theme.top_nav.text_color};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("${SelectSVGArrow}");
  background-position: center right;
  background-repeat: no-repeat;
  padding-right: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding-left: 0;
  }
  border: none !important;
  &:focus { border: none !important; }
  &::-ms-expand { display: none; }
`;

@inject("store") @observer
class MenusDropdownClass extends MobxComponent<Props, State> {

	@action onMenuChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		this.injected.store.filters.s.menu = value;
		this.injected.store.filters.s.category = "";
		this.injected.store.view.scrollToMenu();
	}

	render() {

		let { filters, menus } = this.injected.store;
		const oldMenuSelectionId = filters.s.menu;

		if (menus.length < 2) return null;
		const menuOptions = menus.filter(m => {
			let allDishesHidden = m.categories ? m.categories.map(c => c.dishes.every(d => d.status === 'hidden')).every(vv => vv === true) : false
				
			return !((m.conditions && m.conditions.hide_unavailable_menu) &&
				((m.categories && m.categories.length == 0) ||
				allDishesHidden))
		})
			.map((menu) => ({
			label: menu.display_name || menu.name,
			value: menu._id
		}));

		return (
			<Wrapper className={this.props.className}>
				<SelectInput
					className="no-round"
					value={oldMenuSelectionId}
					onChange={this.onMenuChange}
					options={menuOptions} />
			</Wrapper>
		);
	}

}

export const MenusDropdown = withTranslation()(MenusDropdownClass);
