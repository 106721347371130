import * as React from "react";
import cn from "classnames";
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import { MobxComponent } from "../../../../mobx/component";
import { OptionListWrapper, OptionListItem } from "../components";
import { withTranslation, WithTranslation } from "react-i18next";
import { DishOptionSetPizzaControl, HitBox, ModalDropContent, Switch, withTheme } from "@lib/components";
import { cloneDeepSafe } from "../../../../mobx/utils";
import { inject, observer } from "mobx-react";
import { RestaurantUtils } from "@lib/common";
import { toJS } from "mobx";
import styled from "styled-components";
import { FaExclamationCircle } from "react-icons/fa";
import { config } from "../../../../../config";
import { nanoid } from "nanoid";

interface Props extends WithTranslation {
	error: boolean;
	option_set: T.Schema.Restaurant.Menu.RestaurantOptionSet;
	option_sets: T.Schema.Order.OrderDish["option_sets"];
	update: (data: T.Schema.Restaurant.Menu.RestaurantOptionSet) => void;
	dish_id: string;
	dish_type: T.Schema.Restaurant.Menu.RestaurantDish["type"],
	totalOptionSets: number;
	point_consumption_id: string;
	choice_index?: number;
	total_points?: number;
}

const Warning = styled.p`
  font-size: 14px;
  margin-top: 15px;
`

interface State {
	isModalOpen: boolean;
}

@inject("store") @observer
class DishModalOptionSetClass extends MobxComponent<Props, State> {
	debug: boolean;

	constructor(props: Props) {
		super(props);
		this.debug = !config.isProduction;
		this.state = { isModalOpen: false }
	}

	onSelectSwitch = (i: number) => {
		const option_set = cloneDeepSafe(this.props.option_set);

		if (option_set && option_set.options && option_set.options[i]) {
			const { conditions } = option_set;
			if (conditions.multi_select) {
				if (conditions.max_options && option_set.options.filter(os => os.quantity == 1).length >= (conditions.max_options || 0) ) {
					if(option_set.options.filter(os => os.quantity == 1)
						.findIndex( os => os._id === option_set.options[i]._id) > -1) {
						option_set.options[i].quantity = 0;
					}
				} else {
					const current = option_set.options[i].quantity;
					option_set.options[i].quantity = current === 0 ? 1 : 0;
				}
			} else {
				for (const [index, option] of option_set.options.entries()) {
					if (index === i) {
						const current = option.quantity;
						option_set.options[index].quantity = current === 0 ? 1 : 0;
					} else {
						option_set.options[index].quantity = 0;
					}
				}
			}

			this.props.update(option_set);
		}
	};

	getTotalPoints = (): number => {
		return this.props.total_points || 0;
	}

	onChangeHitbox = (i: number, direction: "up" | "down" | "custom", custom = 0) => {
		const option_set = cloneDeepSafe(this.props.option_set);
		if (option_set) {
			if (option_set.options[i].status) {
				return;
			}
			const current = option_set.options[i].quantity;
			if (direction === "up" && current > 100) return;
			if (direction === "down" && current === 0) return;
			if (direction === "up") {
				option_set.options[i].quantity = current + 1;
			} else if (direction === "down") {
				option_set.options[i].quantity = current - 1;
			} else {
				option_set.options[i].quantity = custom;
			}
			this.props.update(option_set);
		}
	};

	calculateMaxFreeOptions = (): number => {
		const { store } = this.injected;
		const totalPoints = this.getTotalPoints();
		let points: Array<number> = []
		const options = this.props.option_set.options;
		for (const option of options) {
			points.push(store.dish.getOptionPoints(this.props.option_sets, this.props.option_set, option))
		}

		points.sort();

		let sum = 0;
		let count = 0;
		for (const point of points) {
			sum += point;
			if (sum <= totalPoints) {
				count++
			}
		}

		return count;
	}

	constructPointMessage = () => {
		const { t, store } = this.injected;
		const totalPoints = this.getTotalPoints();
		const consumedPoints = store.dish.pointConsumed;
		const maxFree = this.calculateMaxFreeOptions();

		if (consumedPoints <= 0 && maxFree) {
			return t('store.modals.dish.option_sets.points.none_consumed', { maxFree });
		}

		if (consumedPoints < totalPoints) {
			return t('store.modals.dish.option_sets.points.less_than_total_points');
		}

		if (consumedPoints >= totalPoints) {
			return t('store.modals.dish.option_sets.points.greather_than_total_points');
		}

		return '';
	}

	computeDishPointAndFreeOptions = (
		optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet,
		option: T.Schema.Restaurant.Menu.RestaurantOptionSetOption
	) => {
		const { store } = this.injected;

		store.dish.initDishPointItem(
			this.props.point_consumption_id,
			this.props.dish_id,
			this.props.dish_type,
		)

		store.dish.updateDishPointItem(
			this.props.point_consumption_id,
			this.props.dish_id,
			this.props.option_sets,
			optionSet,
			option,
			this.debug
		);
	}

	onChangePizzaControl = (
		optionIdx: number,
		position: T.Schema.Restaurant.Menu.RestaurantOptionSetOptionToppingPosition,
		multiplier: T.Schema.Restaurant.Menu.RestaurantOptionSetOptionToppingQuantityMultiplier
	) => {
		const optionSet = cloneDeepSafe(this.props.option_set);
		if (optionSet.options[optionIdx].status) {
			return;
		}

		let option = cloneDeepSafe(optionSet.options[optionIdx]);

		// If position or quantity multiplier is not defined
		// we remove the 'position' and 'quantity_multiplier' attributes from
		// options set option.
		if (!position || multiplier === 'none') {
			option.quantity = 0;
			delete option.position;
			delete option.quantity_multiplier;
		} else {
			option.quantity = 1;
			option.position = position;
			option.quantity_multiplier = multiplier;
		}

		this.computeDishPointAndFreeOptions(optionSet, option);
	}

	constructOptionSetDescription = () => {
		let intlKey;
		let intlValues: T.ObjectAny = {};
		const conditions = this.props.option_set.conditions;
		const { t } = this.injected;

		if (conditions.multi_select) {
			if (conditions.required && conditions.max_options) {
				intlKey = "select_between";
				intlValues = {
					min: conditions.min_options || 0,
					max: conditions.max_options,
				};
			} else if (conditions.required && conditions.min_options) {
				intlKey = "select_at_least";
				intlValues = { qty: conditions.min_options || 1 };
			} else if (conditions.max_options) {
				intlKey = "select_up_to";
				intlValues = { qty: conditions.max_options };
			} else if (conditions.required){
				intlKey = "select_multiple";
			} else {
				intlKey = "select_multiple_optional";
			}
		} else {
			intlKey = conditions.required ? "select_one" : "select_one_optional";
		}

		const description = t(
			`store.modals.dish.option_sets.conditions.${intlKey}`,
			intlValues
		);

		return description;
	}

	getOptionSetFreeQuantity = (): [boolean, string] => {
		const optionSet = this.props.option_set;
		const conditions = this.props.option_set.conditions;
		const totalOptionSets = this.props.totalOptionSets;
		const { t } = this.injected;

		const totalSelected = optionSet.options.reduce((total, o) => total + o.quantity, 0);
		const freeQty = conditions.free_amount;

		const freeQtyPassed = freeQty ? totalSelected >= freeQty : true;

		const freeDescription =
			totalOptionSets > 1 && freeQty
				? t("store.modals.dish.option_sets.conditions.free_amount", {
					qty: conditions.free_amount,
					selected: totalSelected,
				})
				: "";

		return [freeQtyPassed, freeDescription]
	}

	getControlType = () => {
		const optionSet = this.props.option_set;
		const conditions = this.props.option_set.conditions;

		let controlType =
			conditions.multi_select && conditions.quantity_select
				? "hitbox"
				: "switch";

		if (optionSet.using_points) {
			controlType = "point"
		}

		return controlType;
	}

	isPriceDataAvailable = (
		optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet,
		optionSets: T.Schema.Order.OrderDish["option_sets"],
		getOptionSetRequired: boolean = false
	) => {
		const dependedOptionSet = optionSets.find(os => os._id === optionSet.variable_price_ref);
		const isDependedOptionSetRequired = dependedOptionSet && dependedOptionSet.conditions.required;

		if (isDependedOptionSetRequired) {
			const isRequiredDataAvailable = dependedOptionSet?.options.find(os => os.quantity > 0);
			const listOptionalChoice = optionSets.filter(os => os._id !== dependedOptionSet?._id);

			let listOptionSetOfOptional = listOptionalChoice.map(item => item.options);

			const isOptionalDataAvailable = listOptionSetOfOptional[0].filter(i => i.quantity > 0);

			if (!isRequiredDataAvailable && isOptionalDataAvailable.length > 0 && !getOptionSetRequired) {
				throw new Error(`Please select one "${dependedOptionSet?.display_name}" option first.`);
			} else if (!isRequiredDataAvailable && getOptionSetRequired) {
				return dependedOptionSet;
			}
		}
	}


	render() {
		const { t, store, theme } = this.injected;
		const optionSet = this.props.option_set;
		const optionSets = this.props.option_sets;
		const { error, dish_id, choice_index } = this.props;

		const description = this.constructOptionSetDescription();
		const [freeQtyPassed, freeDescription] = this.getOptionSetFreeQuantity();
		const controlType = this.getControlType();

		this.isPriceDataAvailable(optionSet, optionSets);

		const dependedOptionSet = this.isPriceDataAvailable(optionSet, optionSets, true);
		// const pointMessage = this.constructPointMessage();

		return (
			<ModalDropContent
				title={
					<div>
						<p className={cn("font-semi-bold", { "error-text": error })}>
							{optionSet.display_name || optionSet.name}
						</p>

						<p className={cn("small m-t-1", { "error-text": error })}>
							{description}
						</p>

						{freeDescription && (
							<p className={cn("small m-t-1 italic")}>{freeDescription}</p>
						)}

						{this.state.isModalOpen && dependedOptionSet && dependedOptionSet._id !== optionSet._id && (
							<Warning style={{ color: "#f89406" }}>
								<FaExclamationCircle className="m-r-2" />
								{t(`Please select the "${dependedOptionSet.display_name ? dependedOptionSet.display_name : dependedOptionSet.name}" first.`)}
							</Warning>
						)}

						{/* {optionSet.using_points && (
              <p className={cn("font-semi-bold small m-t-1")}>{pointMessage}</p>
            )} */}
					</div>
				}
				onDrop={(dropped) => this.setState({ isModalOpen: dropped })}
				paddingtb={15}
				paddinglr={25}
				cPaddingtb={15}
				cPaddinglr={25}
			>

				<OptionListWrapper>
					{optionSet.options.map((o, i) => {
						const isPriceDataAvailable = !_isEmpty(RestaurantUtils.dish.getOptionSetOptionPricingData(optionSets, optionSet, o));
						const price = RestaurantUtils.dish.calculateOptionSetOptionPrice(o, optionSet, optionSets);
						const showPrice = price && freeQtyPassed;
						const stock = store.restaurantStock.option_set_options[o._id];
						const isNoStock = typeof stock === "number" && stock <= 0;
						const isUnavailable = o.status === "not-available" || isNoStock;
						return (
							<OptionListItem
								key={i}
								cursor={isUnavailable ? "initial" : ""}
								onClick={
									controlType === "switch"
										? () => {
											if (!isUnavailable) {
												this.onSelectSwitch(i);
											}
										}
										: () => { }
								}
							>
								<div className="p-r-1 flex-line centered">
									<p className="small">{o.name}</p>
									{isUnavailable && (
										<p className="m-l-2 smaller error-text">
											{t("restaurant.menus.option_set.option.unavailable")}
										</p>
									)}
								</div>
								<div className="flex-line centered">
									{!!(showPrice && controlType !== "point") && (
										<p className="p-r-2 small">
											+{t("currency", { value: price })}
										</p>
									)}

									{controlType === "switch" && (
										<Switch
											id={`${o._id}-${i}-${dish_id}-${choice_index || 0}-${nanoid()}`}
											name={`${o._id}-${i}-${dish_id}-${choice_index || 0}-${nanoid()}`}
											checked={o.quantity >= 1}
											onChange={() => {
												if (!isUnavailable) {
													this.onSelectSwitch(i);
												}
											}}
										/>
									)}

									{controlType === "hitbox" && (
										<HitBox
											up={() => {
												if (!isUnavailable) {
													this.onChangeHitbox(i, "up");
												}
											}}
											down={() => {
												if (!isUnavailable) {
													this.onChangeHitbox(i, "down");
												}
											}}
											value={o.quantity}
											small={true}
											editable={true}
											onChange={(e) => {
												const parsed = parseInt(e, 10);
												let val = 0;
												if (!isNaN(parsed)) { 
													if (parsed < 0) val = parsed * -1
													else if(parsed > 100) val = 100
													else val = parsed
												}
												this.onChangeHitbox(i, "custom", val)

											}}
										/>
									)}

									{controlType === "point" && (
										<DishOptionSetPizzaControl
											price={'+' + t("currency", { value: price })}
											rawPrice={price}
											showPrice={showPrice}
											option={o}
											isPriceDataAvailable={isPriceDataAvailable}
											onUpdate={(position, multiplier) => {
												this.onChangePizzaControl(i, position, multiplier);
											}}
											theme={theme}
										/>
									)}
								</div>
							</OptionListItem>
						);
					})}
				</OptionListWrapper>
			</ModalDropContent>
		);
	}
}

// @ts-ignore
export const DishModalOptionSet = withTheme(withTranslation()(DishModalOptionSetClass));
