import * as React from "react";
import { styled, shadeAlternate, DotsLoader, TabSelect, withTheme } from "@lib/components";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/component";
import { FaBars } from "react-icons/fa";
import AnimateHeight from "react-animate-height";
import { action } from "mobx";

const Wrapper = styled("div")`
  position: relative;
  border-top: 1px dashed ${({ theme }) => shadeAlternate(0.15, theme.top_nav.background_color)};
  padding: 0 20px;
`;

const Inner = styled("div")`

`;

const Content = styled("div")`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.content_width.lg}px;
  margin: 0 auto;
`;

const IconButton = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 11px 0;
  // height: 42px;
  cursor: pointer;
  font-size: 14px;
  svg {
    font-size: 16px;
    margin-left: 10px;
  }
`;

const Dropdown = styled("div")`
  padding: 0 20px;
  background: ${({ theme }) => theme.top_nav.background_color};
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => shadeAlternate(0.15, theme.top_nav.background_color)};
  .dd-content {
    width: 100%;
    max-width: ${({ theme }) => theme.content_width.lg}px;
    margin: 0 auto;
    padding: 0 0 10px 0;
    > div {
      cursor: pointer;
      border-radius: 5px;
      &.active {
        background: ${({ theme }) => shadeAlternate(0.08, theme.top_nav.background_color)};
      }
      padding: 10px 10px;
    }
  }
`;

const Tabs = styled("div")`
  flex-grow: 1;
`;

@inject("store") @observer
class NavigationMenus1Class extends MobxComponent<{}, { mounted: boolean, active: boolean }> {

	state = {
		mounted: false,
		active: false,
	};

	componentDidMount() {
		this.setState({ mounted: true });
	}

	@action onMenuChange = (id: string) => {
		this.setState({ active: false });
		this.injected.store.filters.s.menu = id;
		this.injected.store.filters.s.category = "";
		this.injected.store.view.scrollToMenu();
	}

	render() {
		const { theme, store } = this.injected;
		const menus = store.menus;

		if (menus.length === 1) {
			return null;
		}
		return (
			<Wrapper>
				{!this.state.mounted && (
					<DotsLoader
						size={10}
						style={{ height: "43px" }}
						className="flex-center"
						color={theme.top_nav.text_color}
					/>
				)}
				{this.state.mounted && (
					<Inner>
						<Content className="hide-above-sm">
							<IconButton onClick={() => this.setState({ active: !this.state.active })}>
								View Menus
								<FaBars />
							</IconButton>
							<Dropdown>
								<AnimateHeight duration={260} height={this.state.active ? "auto" : 0}>
									<div className="dd-content">
										{menus.map((m, i) => {
											let allDishesHidden = m.categories ? m.categories.map(c => c.dishes.every(d => d.status === 'hidden')).every(vv => vv === true) : false
											
										if((m.conditions && m.conditions.hide_unavailable_menu) &&
											((m.categories && m.categories.length == 0) ||
											allDishesHidden)) {
												return null
										}
										return (
											<div
												key={i}
												onClick={() => this.onMenuChange(m._id)}
												className={store.filters.s.menu === m._id ? "active" : ""}>
												{m.display_name || m.name}
											</div>
										)})}
									</div>
								</AnimateHeight>
							</Dropdown>
						</Content>
						<Content className="hide-below-flex-sm">
							<Tabs>
								<TabSelect
									id="menu-tab-select"
									background={theme.top_nav.background_color}
									screenWidth={store.view.s.screen_width}
									value={store.filters.s.menu}
									values={menus.map((m) => ({
										value: m._id,
										label: m.display_name || m.name,
										conditions: m.conditions,
										categories: m.categories
									}))}
									onChange={(v) => this.onMenuChange(v.value)} />
							</Tabs>
						</Content>
					</Inner>
				)}
			</Wrapper>
		);
	}

}

export const NavigationMenus1 = withTheme(NavigationMenus1Class);
