import axios from "axios";
import { config } from "./config";
import { logger } from "@lib/common";

interface APIHandlers {
	auth_token_error: () => void;
}

export class APIClass {

	request = async (args: T.API.RequestArgs): Promise<any> => {
		const { path, data, params } = args;
		try {
			const res = await axios({
				baseURL: config.services.api.url + "/",
				url: path,
				method: "post",
				headers: { "Authorization-Server": config.services.api.private_key },
				params: params || {},
				data: data || {},
			});
			return res.data;
		}
		catch (e) {
			if (e.response && e.response.data) {
				if (e.response.data.message === "Invalid Authentication") {
					logger.warn("INVALID SERVER PRIVATE KEY");
				}
				throw e.response.data;
			}
			else {
				throw e;
			}
		}
	}

	reseller_domains = async (): Promise<T.API.ResellerFindDomainsResponse> => {
		return this.request({
			path: "/resellers/domains",
		});
	}

	restaurant_find = async (data: T.API.StoresRestaurantFindRequest): Promise<T.API.StoresRestaurantFindResponse> => {
		return this.request({
			path: "/stores/restaurant/find",
			data: data,
		});
	}

}

export const API = new APIClass();
