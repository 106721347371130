import React from "react";
import { BaseInputCSS, Button, CS, Input, RotateLoader } from "@lib/components";
import NumberFormat from "react-number-format";
import { genRecaptchaToken } from '../../../../libs/grecaptcha';
import axios from 'axios';
import { config } from '../../../../../config';
import { decryptData } from '../../../../libs/decrypt-data';
import styled from "styled-components";
import { velocityWorker } from '../../../../libs/velocity-worker';
// import "./checkout.css"


const StyledNumberFormat = styled(NumberFormat) <T.Lib.Form.Input.InputProps>`
  ${BaseInputCSS};
  ${(props) => props.shadow ? CS.shadow.light[props.shadow] : ""};
`;

const PaymentForm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
`

const PaymentCardWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	& input {
		width: 100%;

		@media (min-width: 420px) {
			width: 100px;
		}
	}
`


interface Props {
	changeAuthorizedOpaqueData: Function;
	authorized_data: any;
	amount: number;
	onError: any;
	publicKey: string;
	onCardVerified: () => void;
	onReset: () => void;
}
interface State {
	errorMessage: string;
	isVerifying: boolean;
}

async function validateToken(token: string, publicKey: string) {
	const response = await axios({
		baseURL: config.api_url + '/',
		url: 'stores/token/validate',
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization-Store': publicKey
		},
		data: {
			recaptchaToken: token,
		},
	})

	return response.data.outcome === 0;
}

async function getKey(publicKey: string) {
	const response = await axios({
		baseURL: config.api_url + '/',
		url: 'stores/authnet/get-data',
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization-Store': publicKey
		},
	})

	return response.data;
}

class AuthorizedForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			errorMessage: "Card not verified",
			isVerifying: false,
		}
	}
	componentDidMount = () => {
		window.authorized_data = {
			opaqueData: {
				dataDescriptor: "",
				dataValue: ""
			}
		};

		this.props.onReset()

	}

	sendPaymentDataToAnet = async () => {
		this.setState({
			isVerifying: true
		})
		if (!velocityWorker()) {
			this.setState({
				errorMessage: "Please wait a moment before trying again",
				isVerifying: false
			});
			return
		}
		const token = await genRecaptchaToken();
		const getValue = (value: string) => {
			// @ts-ignore
			return document.getElementById(value).value;
		}

		const { avs } = this.props.authorized_data;

		var cardData: any = {};
		cardData.cardNumber = getValue("cardNumber");
		cardData.month = getValue("expMonth");
		cardData.year = getValue("expYear");
		cardData.cardCode = getValue("cardCode");
		avs && (cardData.zip = getValue("zipCode"));
		if (!cardData.cardNumber || !cardData.month || !cardData.year || !cardData.cardCode || (avs && !cardData.zip)) {
			this.setState({
				errorMessage: "Please fill out all fields",
				isVerifying: false
			});
			return
		}
		const isValidToken = await validateToken(token, this.props.publicKey);
		if (!isValidToken) {
			this.setState({
				errorMessage: "Cannot verify card at this time", // todo: translate later
				isVerifying: false 
			});
			return
		}
		const res = await getKey(this.props.publicKey);
		if (res.outcome === 1) {
			this.setState({
				errorMessage: "Issue with card information. Please try again.",
				isVerifying: false
			});
			return
		}
		const { authorized_client_key, authorized_login_id} = decryptData(res.data);
		var authData: any = {};
		authData.clientKey = authorized_client_key;
		authData.apiLoginID = authorized_login_id;
		//
		var secureData: any = {};
		secureData.authData = authData;
		secureData.cardData = cardData;
		//
		window.Accept.dispatchData(secureData, this.responseHandler);
		// For local dev:
		// this.responseHandler({
		// 	messages: {
		// 		resultCode: "Ok",
		// 	},
		// 	opaqueData: {
		// 		dataDescriptor: "",
		// 		dataValue: "",
		// 	},
		// })
	}
	responseHandler = (response: any) => {
		if (response.messages.resultCode === "Error") {
			var i = 0;
			while (i < response.messages.message.length) {
				console.log(
					response.messages.message[i].code + ": " +
					response.messages.message[i].text
				);
				i = i + 1;
			}
			this.setState({
				errorMessage: "Issue with card information. Please try again.",
				isVerifying: false
			});
			this.props.onError("payment_fail");
			window.authorized_data = {
				opaqueData: {
					dataDescriptor: "",
					dataValue: "",
				},
			};
		} else {
			if (response.messages.resultCode === "Ok") {
				this.setState({
					errorMessage: "Card has been verified",
					isVerifying: false
				})
				this.props.onCardVerified()

				// this.props.onError("payment_fail");
				// this.props.onError("");
				// console.log({ response })
				// @ts-ignore


				window.authorized_data = {
					opaqueData: response.opaqueData,
					...(this.props.authorized_data.avs && {
						// @ts-ignore
						zipCode: document.getElementById("zipCode").value,
					}),
				};
			} else {
				this.setState({
					errorMessage: "Issue with card information. Please try again."
				})
				this.props.onError("payment_fail");
				window.authorized_data = {
					opaqueData: {
						dataDescriptor: "",
						dataValue: "",
					},
				};
			}
		}
	}
	render() {
		const { errorMessage, isVerifying } = this.state
		const isVerified = (errorMessage === 'Card has been verified')
		return (
			<div>
				<PaymentForm id="paymentForm">
					<StyledNumberFormat
						type="text"
						name="cardNumber"
						id="cardNumber"
						placeholder="Card Number"
						readOnly={isVerified}
					/>
					<PaymentCardWrapper>
					<PaymentCardWrapper style={{justifyContent: 'start'}}>
						<Input
							placeholder="Month"
							type="text"
							name="expMonth"
							id="expMonth"
							readOnly={isVerified}/>
						<Input
							placeholder="Year"
							type="text"
							name="expYear"
							id="expYear"
							readOnly={isVerified}/>
						{this.props.authorized_data.avs && <Input
							placeholder="Zip Code"
							type="text"
							name="zipCode"
							id="zipCode"
							readOnly={isVerified} />}
					</PaymentCardWrapper>
				
					<Input
						type="text"
						name="cardCode"
						id="cardCode"
						placeholder="CVV"
						readOnly={isVerified}
					/>
					</PaymentCardWrapper>
					<input type="hidden" name="dataValue" id="dataValue" />
					<input type="hidden" name="dataDescriptor" id="dataDescriptor" />
					<Button
						id="checkoutButton"
						full={true}
						color="primary"
						type="button"
						onClick={(e) => this.sendPaymentDataToAnet()}
						disabled={isVerified}
					>
						{!isVerifying && 'Verify'}
						{isVerifying && <RotateLoader size={2} />}
					</Button>
				</PaymentForm>
				{ this.state.errorMessage && <p 
					className={`text-center small ${isVerified ? 'success-text': 'error-text'}`}
					style={{
						paddingTop: 12,
						paddingBottom: 12}}>
					{!isVerifying && this.state.errorMessage}
				</p>}
			</div>
			// <Box className="App" p={3}>
			//   </Box>
		);
	}
}

export default AuthorizedForm;
