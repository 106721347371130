import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { Button, RotateLoader, BlockError, ModalDropContent, FormDropzone } from "@lib/components";
import { logger } from "@lib/common";
import FD from "form-data";
import { config } from '../../../../server/config';

interface Props extends WithTranslation { }
interface State {
	restart: boolean;
	loading: boolean;
	files: File[];
	error: string;
}

const S3_BASE_URL = `https://s3.amazonaws.com/${config.services.s3.bucket}`;

@inject("store") @observer
class CustomerAgeVerificationClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			restart: false,
			loading: false,
			files: [],
			error: "",
		};
	}

	ageVerificationUpload = async () => {
		try {
			const { store } = this.injected;
			const { files } = this.state;
			if (files.length === 0) {
				this.setState({ error: "Please add ID files before upload" });
				return;
			}
			this.setState({ loading: true, error: "" });
			const data = new FD();
			data.append("customer_id", store.customer.s.item!._id);
			for (const file of files) {
				data.append("upload_files", file);
			}
			const res = await store.api.customerAgeVerificationUpload(data);
			if (res.outcome) {
				this.setState({
					loading: false,
					error: res.message,
				});
			}
			else {
				store.customer.update({ item: res.customer });
				this.setState({ restart: false, loading: false, error: "", files: [] });
			}
		}
		catch (e) {
			logger.captureException(e);
			this.setState({
				loading: false,
				error: "An error occurred, try again or contact us",
			});
		}
	}

	restart = () => {
		this.setState({ restart: true });
	}

	restartCancel = () => {
		this.setState({ restart: false });
	}

	render() {
		if (!this.state) return null;
		const { restart, files, loading, error } = this.state;
		const { store, t } = this.injected;
		const c = store.customer.s.item!;
		const r = store.restaurant;
		const { images, status } = c.age_verification;
		const av = r.settings.business.age_verification;
		if (!av.enabled) return null;
		return (
			<ModalDropContent title={(
				<div>
					<h4>{t("store.modals.customer.age_v.title")}</h4>
					{!status && (
						<p className="small m-t-1 lhp">{t("store.modals.customer.age_v.required_for_items")}</p>
					)}
					{!status && (
						<p className="error-text small font-semi-bold underline m-t-1">{t("store.modals.customer.age_v.status.unverified")}</p>
					)}
					{status === "pending" && <p className="warning-text small font-semi-bold underline m-t-1">{t("store.modals.customer.age_v.status.pending")}</p>}
					{status === "approved" && <p className="success-text small font-semi-bold underline m-t-1">{t("store.modals.customer.age_v.status.approved")}</p>}
					{status === "rejected" && <p className="error-text small font-semi-bold underline m-t-1">{t("store.modals.customer.age_v.status.rejected")}</p>}
				</div>
			)}>
				{(!status || restart) && (
					<div>
						<p className="small font-semi-bold lhp">{t("store.modals.customer.age_v.min_age", { age: av.min_age })}</p>
						<p className="small font-semi-bold lhp m-b-2">{t("store.modals.customer.age_v.est_time", { age: av.estimated_wait_time })}</p>
						<p className="small lhp m-b-4">{t("store.modals.customer.age_v.upload_description")}</p>
						<FormDropzone
							files={files}
							accept="image/*"
							maxFiles={2}
							maxSizeMegaBytes={2}
							onChange={(f) => this.setState({ files: f })}
						/>
						{error && <BlockError className="m-t-4" text={error} />}
						<Button size="xs" color="primary-inverse" className="m-t-4" full={true} onClick={this.ageVerificationUpload}>
							{!loading && t("store.modals.customer.age_v.upload")}
							{loading && <RotateLoader size={2} />}
						</Button>
						{restart && (
							<Button size="xs" color="primary-inverse" className="m-t-2" full={true} onClick={this.restartCancel}>
								{t("store.modals.customer.age_v.go_back")}
							</Button>
						)}
					</div>
				)}
				{(status === "pending" && !restart) && (
					<div>
						<p className="small lhp m-b-2">{t("store.modals.customer.age_v.pending_description_1")}</p>
						<div className="">
							{images.map((img, i) => (
								<p key={i} className="small m-b-2"><a className="link m-r-2" target="_blank" href={`${S3_BASE_URL}/${img}`}>{t("store.modals.customer.age_v.view_document", { number: i + 1 })}</a></p>
							))}
						</div>
						<p className="small lhp m-b-4 font-bold italic">{t("store.modals.customer.age_v.pending_description_2", { time: av.estimated_wait_time })}</p>
						<Button size="xs" color="primary-inverse" className="m-t-4" full={true} onClick={this.restart}>
							{t("store.modals.customer.age_v.reupload")}
						</Button>
					</div>
				)}
				{status === "approved" && (
					<div>
						<p className="lhp">{t("store.modals.customer.age_v.approved_description")}</p>
					</div>
				)}
				{(status === "rejected" && !restart) && (
					<div>
						<p className="lhp">{t("store.modals.customer.age_v.rejected_description")}</p>
						<Button size="xs" color="primary-inverse" className="m-t-4" full={true} onClick={this.restart}>
							{t("store.modals.customer.age_v.reupload")}
						</Button>
					</div>
				)}
			</ModalDropContent>
		);
	}

}

export const CustomerAgeVerification = withTranslation()(CustomerAgeVerificationClass);
