import axios from 'axios';
import autobind from 'autobind-decorator';
import { config } from '../../config';
import { logger } from '@lib/common';
import { RootStore } from './store';

@autobind
export class APIStore {
  store: RootStore;

  constructor(store: RootStore) {
    this.store = store;
  }

  async request(args: T.API.RequestArgs): Promise<any> {
    const { public_key } = this.store.restaurant;
    const { path, data, params, headers } = args;
    try {
      const res = await axios({
        baseURL: config.api_url + '/',
        url: path,
        method: 'post',
        headers: {
          'Authorization-Store': public_key,
          ...(headers || {}),
        },
        params: params || {},
        data: data || {},
      });
      return res.data;
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.message === 'Invalid Authentication') {
          logger.warn('INVALID SERVER PRIVATE KEY');
        }
        throw e.response.data;
      } else {
        throw e;
      }
    }
  }

  async request_post(args: T.API.RequestArgs): Promise<any> {
    const { public_key } = this.store.restaurant;
    const { path, data, params, headers } = args;
    const url = config.api_url + path;
    try {
      const res = await axios.post(url, data, {
        headers: {
          'Authorization-Store': public_key,
          ...(headers || {}),
        },
      });
      return res.data;
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.message === 'Invalid Authentication') {
          logger.warn('INVALID SERVER PRIVATE KEY');
        }
        throw e.response.data;
      } else {
        throw e;
      }
    }
  }

  async request_get(args: T.API.RequestArgs): Promise<any> {
    const { public_key } = this.store.restaurant;
    const { path, data, params, headers } = args;
    try {
      const res = await axios({
        baseURL: config.api_url + '/',
        url: path,
        method: 'get',
        headers: {
          'Authorization-Store': public_key,
          ...(headers || {}),
        },
        params: params || {},
      });
      return res.data;
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.message === 'Invalid Authentication') {
          logger.warn('INVALID SERVER PRIVATE KEY');
        }
        throw e.response.data;
      } else {
        throw e;
      }
    }
  }

  async contactForm(
    data: T.API.StoresRestaurantContactFormRequest
  ): Promise<T.API.StoresRestaurantContactFormResponse> {
    return this.request({
      path: '/stores/restaurant/contact-form',
      data: data,
    });
  }

  async customerLoginFacebook(
    data: T.API.StoresCustomerLoginFacebookRequest
  ): Promise<T.API.StoresCustomerLoginFacebookResponse> {
    return this.request({
      path: '/stores/customer/login/facebook',
      data: data,
    });
  }

  async customerLoginNewGoogle(
    data: T.API.StoresCustomerLoginNewGoogleRequest
  ): Promise<T.API.StoresCustomerLoginGoogleResponse> {
    return this.request({
      path: '/stores/customer/login/google',
      data: data,
    });
  }

  async customerAgeVerificationUpload(data: any): Promise<T.API.StoresCustomerAgeVerificationUploadResponse> {
    return this.request_post({
      path: '/stores/customer/age-verification/upload',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async customer_register_commence(
    data: T.API.CustomerRegisterCommenceRequest
  ): Promise<T.API.CustomerRegisterCommenceResponse> {
    return this.request({
      path: '/stores/customer/register/commence',
      data: data,
    });
  }

  async customer_register_complete(
    data: T.API.CustomerRegisterCompleteRequest
  ): Promise<T.API.CustomerRegisterCompleteResponse> {
    return this.request({
      path: '/stores/customer/register/complete',
      data: data,
    });
  }

  async customer_login(data: T.API.CustomerLoginRequest): Promise<T.API.CustomerLoginResponse> {
    return this.request({
      path: '/stores/customer/login',
      data: data,
    });
  }

  async customer_logged_in_change_password(
    data: T.API.CustomerLoggedInPasswordChangeInitRequest
  ): Promise<T.API.CustomerLoggedInPasswordChangeInitResponse> {
    return this.request({
      path: '/stores/customer/logged-in-change-password',
      data: data,
    });
  }

  async customer_logged_in_change_email(
    data: T.API.CustomerLoggedInEmailChangeInitRequest
  ): Promise<T.API.CustomerLoggedInEmailChangeInitResponse> {
    return this.request({
      path: '/stores/customer/logged-in-change-email',
      data: data,
    });
  }

  async customer_reset_password_init(
    data: T.API.CustomerPasswordResetInitRequest
  ): Promise<T.API.CustomerPasswordResetInitResponse> {
    return this.request({
      path: '/stores/customer/reset-password/init',
      data: data,
    });
  }

  async customer_reset_password_complete(
    data: T.API.CustomerPasswordResetCompleteRequest
  ): Promise<T.API.CustomerPasswordResetCompleteResponse> {
    return this.request({
      path: '/stores/customer/reset-password/complete',
      data: data,
    });
  }

  async customer_refresh(data: T.API.CustomerTokenRefreshRequest): Promise<T.API.CustomerTokenRefreshResponse> {
    return this.request({
      path: '/stores/customer/refresh',
      data: data,
    });
  }

  async customer_orders(data: T.API.StoresCustomerOrdersRequest): Promise<T.API.StoresCustomerOrdersResponse> {
    return this.request({
      path: '/stores/customer/orders',
      data: data,
    });
  }

  async order_validate(data: T.API.StoresOrderCreateValidateRequest): Promise<T.API.StoresOrderCreateValidateResponse> {
    return this.request({
      path: '/stores/order/create/validate',
      data: data,
    });
  }

  async order_draft_create(data: T.API.StoresOrderBuildRequest): Promise<T.API.StoresOrderCreateResponse> {
    return this.request({
      path: '/stores/order/draft/create',
      data,
    });
  }
  async order_create(data: T.API.StoresOrderCreateRequest): Promise<T.API.StoresOrderCreateResponse> {
    return this.request({
      path: '/stores/order/create',
      data,
    });
  }

  async order_check(data: T.API.StoresOrderCheckRequest): Promise<T.API.StoresOrderFindResponse> {
    return this.request({
      path: '/stores/order/check',
      data: data,
    });
  }
  async order_find(data: T.API.StoresOrderFindRequest): Promise<T.API.StoresOrderFindResponse> {
    return this.request({
      path: '/stores/order/find',
      data: data,
    });
  }

  async order_eta(data: T.API.StoresOrderETAReq): Promise<T.API.StoresOrderETARes> {
    return this.request({
      path: '/stores/order/eta',
      data: data,
    });
  }

  async order_update_status(
    data: T.API.DashboardOrderUpdateStatusRequest
  ): Promise<T.API.DashboardOrderUpdateStatusResponse> {
    return this.request({
      path: '/stores/order/update/status',
      data: data,
    });
  }

  async booking_create(data: T.API.StoresBookingCreateRequest): Promise<T.API.StoresBookingCreateResponse> {
    return this.request({
      path: '/stores/booking/create',
      data: data,
    });
  }
  async booking_find(data: T.API.StoresBookingFindRequest): Promise<T.API.StoresBookingFindResponse> {
    return this.request({
      path: '/stores/booking/find',
      data: data,
    });
  }

  async convert_currency(data: T.API.StoresConvertCurrencyRequest): Promise<T.API.StoresConvertCurrencyResponse> {
    return this.request({
      path: '/stores/convert-currency',
      data: data,
    });
  }

  async payment_poli_pay_init(data: T.API.PaymentPOLiPayInitRequest): Promise<T.API.PaymentPOLiPayInitResponse> {
    return this.request({
      path: '/stores/payment/poli-pay/init',
      data: data,
    });
  }

  async createStripeBancontactPaymentIntent(
    data: T.API.StripeLatest.CreateStripeBancontactPaymentIntentRequest
  ): Promise<T.API.StripeLatest.StripePaymentIntentResponse> {
    return this.request({
      path: '/v2/stripe-payment/bancontact/create-payment-intent',
      data,
    });
  }

  async payment_paygate_payweb_init(
    data: T.API.PaymentPaygatePaywebInitRequest
  ): Promise<T.API.PaymentPaygatePaywebInitResponse> {
    return this.request({
      path: '/stores/payment/paygate-payweb/init',
      data: data,
    });
  }

  async createLogStore(
    data: {
      message: string;
      orderId: string;
      sessionId?: string;
    }
  ): Promise<T.API.LogCreateResponse> {
    return this.request({
      path: '/stores/logs/create',
      data: data,
    });
  }

  async payment_ipay88_init(data: T.API.PaymentIpay88InitRequest): Promise<T.API.PaymentIpay88InitResponse> {
    return this.request({
      path: '/stores/payment/ipay88/init',
      data: data,
    });
  }

  async payment_bambora_apac_init(
    data: T.API.PaymentBamboraAPACInitRequest
  ): Promise<T.API.PaymentBamboraAPACInitResponse> {
    return this.request({
      path: '/stores/payment/bambora-apac/init',
      data: data,
    });
  }

  async tokenizeBamboraNaCard(data: T.API.BamboraNaTokenizationRequest): Promise<T.API.BamboraNaTokenizationResponse> {
    return this.request({
      path: '/stores/bambora-na/tokenize-card',
      data,
    });
  }

  async createBamboraPaymentRequest(
    data: T.API.BamboraNaCreatePaymentRequest
  ): Promise<T.API.BamboraNaCreatePaymentResponse> {
    return this.request({
      path: '/stores/bambora-na/create-payment-request',
      data,
    });
  }

  async payment_pesapal_init(data: T.API.PaymentPesapalInitRequest): Promise<T.API.PaymentPesapalInitResponse> {
    return this.request({
      path: '/stores/payment/pesapal/init',
      data: data,
    });
  }

  async payment_card_connect_init(data: any): Promise<T.API.CommonResponse> {
    return this.request({
      path: '/stores/payment/card-connect/init',
      data: data,
    });
  }

  async payment_handle_failed_order(
    data: T.API.PaymentHandleFailedOrderRequest
  ): Promise<T.API.PaymentHandleFailedOrderResponse> {
    return this.request({
      path: '/v2/stripe-payment/wallet/handle-failed-order',
      data: data,
    });
  }

  async custom_payment(data: T.API.StoresCustomPaymentRequest): Promise<T.API.StoresCustomPaymentResponse> {
    return this.request({
      path: '/stores/custom-payment',
      data: data,
    });
  }

  async create_postmates_quote(data: T.API.CreatePostmatesQuoteRequest): Promise<T.API.CreatePostmatesQuoteResponse> {
    return this.request_post({
      path: '/stores/order/postmates/delivery-quotes',
      data,
    });
  }

  async create_lalamove_quotation(data: T.API.CreateLalamoveQuoteRequest): Promise<T.API.CreateLalamoveQuoteResponse> {
    return this.request_post({
      path: '/stores/order/lalamove/delivery-quotes',
      data,
    });
  }

  async create_uber_quote(data: T.API.CreateUberQuoteRequest): Promise<T.API.CreateUberQuoteResponse> {
    return this.request_post({
      path: '/stores/order/uber/delivery-quotes',
      data,
    });
  }

  async get_serviceable_zones(
    data: T.API.ServiceableZoneRequest
  ): Promise<T.API.ServiceableZoneResponse> {
    return this.request_post({
      path: '/stores/order/serviceable-zones',
      data
    })
  }

  async get_walletly_points(params: T.API.GetWalletlyPointRequest): Promise<T.API.LoyaltyGetPointResponse> {
    return this.request_get({
      path: '/v1/loyalty/walletly/get-points',
      params,
    });
  }

  async get_walletly_user_deals(params: T.API.GetUserDealsRequest): Promise<T.API.GetUserDealsResponse> {
    return this.request_get({
      path: '/v1/loyalty/walletly/get-user-deals',
      params,
    });
  }

  async walletly_apply_deals(data: T.API.DealApplyRequest): Promise<T.API.DealApplyResponse> {
    return this.request_post({
      path: '/v1/loyalty/walletly/apply-deal',
      data,
    });
  }

  async getFlowsCW(params: T.API.GetFlowsCWRequest): Promise<T.API.DealApplyResponse> {
    return this.request_get({
      path: '/v1/loyalty/walletly/get-flows-cw',
      params,
    });
  }

  async validationDeal(data: T.API.ValidationDealRequest): Promise<T.API.DealApplyResponse> {
    return this.request_post({
      path: '/v1/loyalty/walletly/deal-validation',
      data,
    });
  }

  async createPayMongoCheckoutSession(
    data: T.API.PayMongoCheckoutSessionRequest
  ): Promise<T.API.PayMongoCheckoutSessionResponse> {
    return this.request_post({
      path: '/stores/paymongo/create-checkout-session',
      data,
    });
  }

  async getStripeTransaction(data: any): Promise<any> {
    return await this.request_post({
      path: '/dashboard/connect/get-transaction',
      data: data,
    });
  }

  async createStripeCardPaymentIntent(
    data: T.API.StripeLatest.CreateStripeCardPaymentIntentRequest
  ): Promise<T.API.StripeLatest.StripePaymentIntentResponse> {
    return await this.request_post({
      path: '/v2/stripe-payment/card/create-payment-intent',
      data,
    });
  }

  async createStripeWalletPaymentIntent(
    data: T.API.StripeLatest.CreateStripeWalletPaymentIntentRequest
  ): Promise<T.API.StripeLatest.StripePaymentIntentResponse> {
    return await this.request_post({
      path: '/v2/stripe-payment/wallet/create-payment-intent',
      data,
    });
  }

  async getServiceLatestVersion(): Promise<T.API.ServiceLatestVersionResponse> {
    return this.request_get({
      path: '/v1/service/versions',
    });
  }
  async getStorePromoCode(data:T.API.StoresRestaurantPromoFindRequest): Promise<T.API.StoresRestaurantPromoFindResponse> {
    return this.request_post({
      path: '/stores/promo/find',
      data,
    });
  }
  async getRestaurant(data:T.API.StoresRestaurantFindRequest): Promise<T.API.StoresRestaurantFindResponse> {
    return this.request_post({
      path: '/stores/restaurant/refresh',
      data,
    });
  }
  async getOrderCountByService(data: any) {
    return this.request_post({
      path: '/stores/order/count-by-service',
      data
    })
  }
}
