import * as React from "react";
import cn from "classnames";
import { observer, inject } from "mobx-react";
import { Button, SelectModal, ModalContent } from "@lib/components";
import { FORMATS } from "@lib/common";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }
interface State {
	dateModal: boolean;
	timeModal: boolean;
	orderDays: any;
	daySelected: any;
	orderTimes: any
}

@inject("store") @observer
class OrderConfigDatetimeClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			dateModal: false,
			timeModal: false,
			orderDays: [],
			daySelected: false,
			orderTimes: []
		};
	}

	async componentDidMount() {
		const oc = this.injected.store.order_config.s;
		if (!oc.date) {
			this.setState({ dateModal: true });
		}
		const { order_config } = this.injected.store;
		const { date } = order_config.s;
		const days = order_config.orderDays;
		this.setState({ orderDays: days });
		if (date) {
			const times = await order_config.orderTimes(date) || [];
			this.setState({ daySelected: true, orderTimes: times });
		}
	}

	async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		const { order_config } = this.injected.store;
		if (prevState.daySelected !== this.state.daySelected && this.state.daySelected && !order_config.s.time) {
			const times = await order_config.orderTimes(order_config.s.date) || [];
			this.setState({ orderTimes: times });
			this.setState({ timeModal: true})
		}
	}

	render() {

		if (!this.state) return null;

		const { store, t } = this.injected;
		const { dateModal, timeModal, orderTimes, orderDays } = this.state;
		const { order_config } = this.injected.store;
		const { date, time, service } = order_config.s;

		const days = orderDays;
		const times = orderTimes;

		let dateButtonText = t("store.modals.order.config.datetime.select_date");
		let timeButtonText = t("store.modals.order.config.datetime.select_time");
		if (date) {
			const parsedDate = store.intl.momentFromFormat(date, FORMATS.moment.date);
			dateButtonText = store.intl.i18n.t("dateFromTimestamp", { value: parsedDate.valueOf() }); // parsedDate.format(FORMATS.localized.date_short);
		}
		if (time) {
			const parsedDateTime = store.intl.momentFromFormat(`${date} ${time}`, FORMATS.moment.datetime);
			timeButtonText = store.intl.i18n.t("timeFromTimestamp", { value: parsedDateTime.valueOf() }); // parsedDateTime.format(FORMATS.localized.time);
		}

		return (
			<div className="m-t-6">

				<div className="text-center max350 center flex-center flex-no-wrap">
					<Button className={cn("font14 max150 flex-grow", date && "no-round-right no-border-right")} onClick={() => this.setState({ dateModal: true })}>
						{dateButtonText}
					</Button>
					{date && (
						<Button className="font14 max150 flex-grow no-round-left" onClick={() => this.setState({ timeModal: true })}>
							{timeButtonText}
						</Button>
					)}
				</div>

				<SelectModal
					innerId="order-date-select"
					title={service === "table_booking" ? t("store.modals.order.config.datetime.select_booking_date") : t("store.modals.order.config.datetime.select_order_date")}
					level={2}
					width={420}
					active={dateModal}
					onSelect={(o) => {
						if (o.value !== date)
							order_config.setDate(o.value);
						this.setState({ dateModal: false, daySelected: o.value });
					}}
					close={() => this.setState({ dateModal: false })}
					options={days}
				/>

				<SelectModal
					innerId="order-time-select"
					title={service === "table_booking" ? t("store.modals.order.config.datetime.select_booking_time") : t("store.modals.order.config.datetime.select_order_time")}
					level={2}
					width={420}
					active={timeModal}
					onSelect={(o) => {
						if (o.value !== time)
							order_config.setTime(o.value);
						this.setState({ timeModal: false });
					}}
					close={() => this.setState({ timeModal: false })}
					options={times}>
					{times.length === 0 && (
						<ModalContent paddingtb={12}>
							<p className="text-center small error-text">{t("store.modals.order.config.datetime.no_times_available")}</p>
						</ModalContent>
					)}
				</SelectModal>

			</div>
		);
	}

}

export const OrderConfigDatetime = withTranslation()(OrderConfigDatetimeClass);
