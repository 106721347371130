import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FaPlus, FaTimesCircle, FaExclamationCircle } from 'react-icons/fa';

import { RestaurantUtils, PromoParser } from '@lib/common';
import { FreeItemsSelectionModal } from './free-items-selection';
import { MobxComponent } from '../../../mobx/component';
import { Input, shadeAlternate, ModalContent, styled, Button, Tag } from '@lib/components';

interface Props extends WithTranslation {
  onClickPlusButton: () => void;
}
interface State {}

const PromoBox = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PromoInputWrapper = styled('div')`
  display: flex;
  align-items: stretch;
  line-height: 0;
  border-bottom: 1px solid ${({ theme }) => theme.box.border};
`;

export const PromoInput = styled(Input)`
  flex-grow: 1;
  background: ${({ theme }) => theme.box.background};
  padding: 23px 25px;
  border: none !important;
  border-radius: 0;
  &:focus {
    border: none !important;
  }
`;

export const PromoButton = styled(Button)`
  border-radius: 0;
  width: 60px;
  height: auto;
  font-size: 14px;
  border-top: none;
  border-left: 1px solid ${({ theme }) => theme.box.border} !important;
  border-right: none;
  border-bottom: none;
  background: ${({ theme }) => theme.box.background};
  color: ${({ theme }) => shadeAlternate(0.1, theme.box.text)};
  &:hover {
    box-shadow: none;
  }
`;

@inject('store')
@observer
class CartPromosClass extends MobxComponent<Props, State> {
  promoErrorRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.promoErrorRef = React.createRef();
  }

  scrollToErrorSection() {
    const { store } = this.injected;
    const element = this.promoErrorRef?.current;
    if (store.cart.form.promo.error && element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  async componentDidMount() {
    const { store } = this.injected;
    await store.cart.promoCodeApply();
    setTimeout(() => this.scrollToErrorSection(), 0);
  }

  componentDidUpdate() {
    this.scrollToErrorSection();
  }

  render() {
    const { t, store } = this.injected;
    const { code, error } = store.cart.form.promo;
    const promo = store.cart.s.promos.length > 0 ? store.cart.s.promos[0] : null;

    if (promo) {
      const prompParser = new PromoParser(promo);
      const limit_to_dishes = prompParser.getLimitToDishes();
      const fixed_discount = prompParser.getFixedDiscount();
      const percent_discount = prompParser.getPercentDiscount();
      const isDiscountPromo = prompParser.getType() === 'discount';

      const promoLimitDishesWarning = !store.cart.promoLimitToDishesValid;
      let promoLimitDishesWarningSingle: T.Schema.Restaurant.Menu.RestaurantDish | null = null;
      if (promoLimitDishesWarning) {
        if (Array.isArray(limit_to_dishes) && limit_to_dishes.length === 1) {
          const find = RestaurantUtils.menu.findDish(store.restaurant, limit_to_dishes[0]);
          if (find) {
            promoLimitDishesWarningSingle = find.dish;
          }
        }
      }
      return (
        <ModalContent paddingtb={25} paddinglr={25}>
          <PromoBox className="">
            <div className="flex-grow">
              <p>
                {promo.name} <span className="font-semi-bold underline">({promo.code})</span>
              </p>
              {(!!fixed_discount || !!percent_discount) && (
                <div className="m-t-2">
                  {!!fixed_discount && fixed_discount > 0 && (
                    <Tag fontSize={14} className="m-r-3">
                      {t('store.modals.cart.promo.fixed_discount', {
                        value: fixed_discount,
                      })}
                    </Tag>
                  )}
                  {!!percent_discount && percent_discount > 0 && (
                    <Tag fontSize={14}>
                      {t('store.modals.cart.promo.percent_discount', {
                        value: percent_discount,
                      })}
                    </Tag>
                  )}
                </div>
              )}
            </div>
            <p className="big cursor" onClick={store.cart.promoRemove}>
              <FaTimesCircle />
            </p>
          </PromoBox>
          {promoLimitDishesWarning && isDiscountPromo && (
            <>
              {!!promoLimitDishesWarningSingle && (
                <p className="error-text m-t-2 small lhp">
                  {' '}
                  {t('store.modals.cart.promo.errors.dishes_required_single', {
                    dish: promoLimitDishesWarningSingle.name,
                  })}
                </p>
              )}
              {!promoLimitDishesWarningSingle && Array.isArray(limit_to_dishes) && limit_to_dishes?.length > 1 && (
                <p className="error-text m-t-2 small lhp">
                  {' '}
                  {t('store.modals.cart.promo.errors.dishes_required_multi')}
                </p>
              )}
            </>
          )}
          {!store.cart.minOrderPromoValid && (
            <p className="error-text m-t-2 small lhp">
              {' '}
              {t('store.modals.cart.errors.min_order_promo', {
                value: store.cart.minOrderPromo,
              })}
            </p>
          )}
        </ModalContent>
      );
    }

    return (
      <>
        <PromoInputWrapper>
          <PromoInput
            name="promo"
            value={code}
            placeholder={t('store.modals.cart.promo.placeholder')}
            onKeyPress={e => (e.key === 'Enter' ? this.props.onClickPlusButton() : null)}
            onChange={e => store.cart.updateFormPromo({ code: e.target.value })}
            disabled={!store.cart.minOrderDeliveryValid}
          />
          <PromoButton
            disabled={!store.cart.minOrderDeliveryValid}
            onClick={this.props.onClickPlusButton}
           >
            <FaPlus />
          </PromoButton>
        </PromoInputWrapper>
        <FreeItemsSelectionModal />

        {error && (
          <ModalContent key="error" className="error-bg" paddinglr={25} paddingtb={12}>
            <p className="small lhp flex-line centered" ref={this.promoErrorRef}>
              <FaExclamationCircle className="m-r-2" />
              {t(`store.modals.cart.promo.errors.${error}`)}
            </p>
          </ModalContent>
        )}
      </>
    );
  }
}

export const CartPromos = withTranslation()(CartPromosClass);
