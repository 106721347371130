const MAX_CLICKS = 1;
const TIME_WINDOW = 10 * 1000;

export function velocityWorker() {
  const limitKey = 'click-attempts';
  const currentTimestamp = Date.now();
  let clickTimestamps = JSON.parse(localStorage.getItem(limitKey) || '[]');

  clickTimestamps = clickTimestamps.filter((timestamp: number) => {
    return currentTimestamp - timestamp < TIME_WINDOW;
  });

  if (clickTimestamps.length >= MAX_CLICKS) {
    return false;
  }

  clickTimestamps.push(currentTimestamp);
  localStorage.setItem(limitKey, JSON.stringify(clickTimestamps));
  return true;
}