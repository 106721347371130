import * as React from 'react';
import cn from 'classnames';
import _flatten from 'lodash/flatten';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PaymentImages } from '@lib/common';
import { MobxComponent } from '../../../mobx/component';
import { config } from '../../../../config';
import { CardConnectForm } from './methods/cardconnect';
import { BamboraApacForm } from './methods/bambora_apac';
import { BamboraNaForm } from './methods/bambora_na';
import { ElavonForm } from './methods/elavon';
import { RedDotForm } from './methods/red_dot';
import { PaymentButtonWrapper, PaymentButton, PaymentImage, PaymentImageWrapper } from './components';
import CheckoutForm from './methods/checkout';
import GravityForm from './methods/gravity';
import ATHMovilForm from './methods/ath_movil';
import AuthorizedForm from './methods/authorized';
import { StripeCard } from './stripe-card/stripe-card';
import { PaymentMethod, filterPaymentMethods } from './util';

interface State {
  methods: Array<PaymentMethod>;
}

interface Props extends WithTranslation {}

@inject('store')
@observer
class CheckoutPaymentClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      methods: filterPaymentMethods(this.injected.store),
    };
  }

  updateAthMovil = (newValue: boolean) => {
    const { store, t } = this.injected;
    const checkout = store.checkout.s;
    checkout.ath_movil_status = newValue;
  };
  changeLoading = (newValue: boolean) => {
    const { store, t } = this.injected;
    store.checkout.s.loading = newValue;
  };
  changeAuthorizedOpaqueData = (newValue: boolean) => {
    const { store, t } = this.injected;
    // store.checkout.s.loading = newValue;
  };
  changeLabelForStripePayment(defaultLabel: string) {
    return defaultLabel === 'Stripe Digital Wallet'
      ? //@ts-ignore
        (window.ApplePaySession ? 'Apple Pay' : 'Google Pay') + ' (Stripe)'
      : defaultLabel;
  }
  updateCurrentPaymentMethod = (method: PaymentMethod) => {
    const { store } = this.injected;
    store.checkout.setPayment(method.key);
    store.checkout.update({ selectedPayment: method.aliasKey || method.key });
    if(method.key === 'paypal') {
      store.checkout.update({
        paypal_show: true
      })
    } else {
      store.checkout.update({
        paypal_show: false
      })
    }
  };

  componentDidMount(): void {
    const { store } = this.injected;
    if(store.checkout.s.payment === 'paypal') {
      //reinitialized
      store.checkout.order_commence()
    }
    const selectedPaymentMethodExists = this.state.methods.some(method => method.key === store.checkout.s.selectedPayment);
    if (!selectedPaymentMethodExists) {
      store.checkout.setPayment('');
      store.checkout.update({ selectedPayment: '' });
    }
  }

  componentDidUpdate(): void {
    const { store } = this.injected
    if(store.checkout.s.payment === 'paypal') {
      //reinitialized
      store.checkout.order_commence()
    }
  }

  render() {
    const { store, t } = this.injected;
    const currency = store.checkout.currency;
    const checkout = store.checkout.s;
    const fees = store.cart.fees;
    const paymentFees = fees.filter(f => f.is_payment_fee);

    const imageBase = config.isTest ? `http://localhost:3000/payment-logos` : `/payment-logos`;
    const images = [];
    for (const m of this.state.methods) {
      const imgs = PaymentImages[m.key as keyof typeof PaymentImages];
      if (imgs) {
        for (const i of imgs) {
          if (images.indexOf(i) === -1) {
            images.push(i);
          }
        }
      }
    }
    return (
      <div>
        <PaymentButtonWrapper>
          {this.state.methods.map((m, i) => {
            let label = m.label || t(`constants.payment.method.${m.key}`);
            if (m.label_delivery && store.order_config.s.service === 'delivery') {
              label = m.label_delivery;
            }
            if (m.key === 'stripe_digital_wallet') return null;
            if(this.state.methods.length === 1 && m.key==='stripe'){
              this.updateCurrentPaymentMethod(m);
            } else {
              return (
                <PaymentButton
                  key={i}
                  title={label}
                  applepay={['checkout_apple_pay' && 'stripe'].includes(m.key) || label.toLowerCase().includes('apple pay')}
                  googlePay={['stripe'].includes(m.key) || label.toLowerCase().includes('stripe')}
                  active={checkout.selectedPayment === (m.aliasKey || m.key)}
                  onClick={() => this.updateCurrentPaymentMethod(m)}
                />
              );
            }
          })}
        </PaymentButtonWrapper>

        

        {checkout.payment === 'stripe' && (
          <>
            {this.state.methods.length === 1 ? (
              <div className="tally">
                <StripeCard />
              </div>
            ) :
           (<div className="p-t-5 tally">
              <StripeCard />
            </div>)}
          </>
        )}
        {checkout.payment === 'cardconnect' && <CardConnectForm />}
        {checkout.payment === 'bambora_apac' && <BamboraApacForm />}
        {checkout.payment === 'bambora_na' && <BamboraNaForm />}

        {currency && currency.isDifferent && (
          <p className="warning-bg m-t-3 p-1 round text-center small">
            {t('store.modals.checkout.warning.different_payment_currency', {
              store_currency: currency.store,
              payment_currency: currency.payment,
            })}
          </p>
        )}

        {checkout.payment === 'checkout' && (
          <CheckoutForm
            checkout_public_key={
              store.restaurant.settings.payments.checkout &&
              store.restaurant.settings.payments.checkout.checkout_public_key
                ? store.restaurant.settings.payments.checkout.checkout_public_key
                : ''
            }
          />
        )}

        {checkout.payment === 'gravity' && (
          <GravityForm
            zip={checkout.zip}
            restaurant_id={store.restaurant._id}
            api_url={config.api_url}
            reference={`${store.restaurant.name} - ${store.cart.store.order_id}`}
            amount={store.cart.total}
            full_name={store.checkout.s.name}
          />
        )}

        {checkout.payment === 'ath_movil' && (
          <ATHMovilForm
            changeLoading={this.changeLoading}
            cart={store.cart}
            ath_movil_data={store.restaurant.settings.payments.ath_movil}
            updateAthMovilStatus={this.updateAthMovil}
            amount={store.cart.total}
            reference={`${store.restaurant.name} - ${store.cart.store.order_id}`}
          />
        )}

        {checkout.payment === 'authorized' && (
          <AuthorizedForm
            changeAuthorizedOpaqueData={this.changeAuthorizedOpaqueData}
            authorized_data={store.restaurant.settings.payments.authorized}
            amount={store.cart.total}
            onError={(error: string) => store.checkout.update({ error })}
            publicKey={store.restaurant.public_key}
            onCardVerified={() => store.checkout.update({
              authorized_card_not_verified: false
            })}
            onReset={() => store.checkout.update({
              authorized_card_not_verified: true
            })}
          />
        )}

        {checkout.payment === 'elavon' && <ElavonForm />}

        {checkout.payment === 'red_dot' && <RedDotForm />}

        {images.length > 0 && (
          <PaymentImageWrapper>
            {images.map((i, k) => (
              <PaymentImage key={k}>
                <img src={`${imageBase}${i}`} alt="" />
              </PaymentImage>
            ))}
          </PaymentImageWrapper>
        )}
        
        {paymentFees.length > 0 &&
          paymentFees.map(({ name, fixed_value, percent_value, value }, i) => (
            <p key={i} className={cn('warning-bg p-1 round text-center small', i === 0 ? 'm-t-3' : 'm-t-2')}>
              {fixed_value > 0 &&
                percent_value > 0 &&
                t('order.totals.fee', {
                  name: name,
                  value: fixed_value,
                  percent: percent_value,
                })}
              {percent_value > 0 &&
                !fixed_value &&
                t('order.totals.fee_percent', {
                  name: name,
                  percent: percent_value,
                })}
              {fixed_value > 0 && !percent_value && t('order.totals.fee_fixed', { name: name })}
              <span className="font-semi-bold"> - {t('currency', { value })}</span>
            </p>
          ))}
      </div>
    );
  }
}

export const CheckoutPayment = withTranslation()(CheckoutPaymentClass);
