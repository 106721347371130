import * as React from "react";
import { css, shadeAlternate, styled } from "@lib/components";

export const TabSelectComponent = styled("div")<{
	background: string;
}>`
	position: relative;
	background: ${({ background }) => background};
	user-select: none;
	width: 100%;
	height: 42px;
	z-index: ${({ theme }) => theme.slide_panel.zindex};
`;

export const TabSelectControlComponent = styled("div")<{
	background: string;
	hide?: boolean;
	side: "left" | "right";
}>`
	z-index: 1;
	display: ${({ hide }) => (hide ? "none" : "flex")};
	justify-content: center;
	align-items: center;
	background: ${({ background }) => background};
	position: absolute;
	height: 42px;
	width: 32px;
	cursor: pointer;
	${({ side, background }) =>
		side === "left"
			? css`
					left: 0;
					border-right: 1px solid ${shadeAlternate(0.1, background)};
			  `
			: css`
					right: 0;
					border-left: 1px solid ${shadeAlternate(0.1, background)};
			  `};
`;

export const TabSelectContentComponent = styled("div")`
	position: absolute;
	top: 10%;
	bottom: 0;
	left: 0;
	right: 0;
	line-height: 0;
	transition: 0.3s all;
`;

export const TabSelectOptionsComponent = styled("div")`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	transition: 0.3s all;
`;
export const TabNameText = styled("p")`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
	font-size: 14px;
`;

export const TabSelectOptionComponent = styled("div")<{
	isHighlighted: boolean;
	highlightSelectedStyle: "bubble" | "underline" | undefined;
	hide?: boolean;
}>`
	display: ${({ hide }) => hide ? 'none': 'flex' };
	justify-content: center;
	align-items: center;
	padding: 0 12px;
	height: 30px;
	
	${({ isHighlighted, highlightSelectedStyle, theme }) => {
		if (isHighlighted) {
			switch (highlightSelectedStyle) {
				case "underline":
					return `border-bottom: 2px solid ${theme.colors.primary}`;

				case "bubble":
				default:
					return `background: ${theme.colors.primary}; color: ${theme.colors.primary_text}; border-radius: 35px;}; font-weight: 600;`;
			}
		}
	}}
`;
