import * as React from "react";
import { observer, inject } from "mobx-react";
import { Button, RotateLoader } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { logger } from "@lib/common";

interface Props extends WithTranslation {}
interface State {
	eta_fetching: boolean;
	eta_timestamp?: number;
	eta_millis_to_customer?: number;
}

@inject("store") @observer
class OrderConfigConfirmedClass extends MobxComponent<Props, State> {

	eta_fetched_key: string = "";
	interval: any;
	_isMounted = false;

	constructor(props: Props) {
		super(props);
		this.state = {
			eta_fetching: true,
		};
	}

	componentDidMount() {
		this.interval = setInterval(() => this.forceUpdate(), 1000);
		this._isMounted = true;
		this.getEta();
	}

	componentDidUpdate() {
		this.getEta();
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this._isMounted = false;
	}

	confirm = async () => {
		const { store } = this.injected;
		const { due } = store.order_config.s;
		let closed = false;
		let expired = false;
		if (due === "now") {
			closed = !store.serviceOpen();
		}
		else if (due === "later") {
			expired = store.order_config.expired();
		}
		if (!closed && !expired) {
      // get order history if user is logged in
      if (store.customer.isLoggedIn) {
        store.order_history.get(1);
      }
			// TODO: SESSION ID GENERATE
			if((localStorage.getItem('initial_order_id') === null ||
				localStorage.getItem('initial_order_id') === '')){
				store.order.generateOrderId(true);
			}
			// else {
				const orderValidate = await store.api.order_check({ _id: localStorage.getItem('initial_order_id') || "",
					isPostHookRequire: false,
					validateLimitOrderReqData: {
						restaurantId: store.restaurant._id,
						// @ts-ignore
						service: store.order_config.s.service,
						// @ts-ignore
						timestamp: store.order_config.s.due === "later" ? store.order_config.s.timestamp : Date.now(),
					}
				});
				if (orderValidate.outcome && orderValidate.message === "exceeded_order_limit") {
					return store.order_config.setIsLimit(true);
				}
				if (!orderValidate.outcome && orderValidate.order.status !== "draft") {
					delete orderValidate.outcome
					// @ts-ignore
					await store.checkout.order_complete_v2(orderValidate);
				}
				store.order.restartOpenReplay();
			// }
			store.order_config.setConfirmed();
			store.validateFilterDatas(store.filters.s);
		}
	}

	async getEta() {

		const { store } = this.injected;
		const oc = store.order_config.s;
		const eta_fetched_key = `${oc.service}${oc.due}${oc.time}${oc.date}${oc.driving_time}`;

		if (this.eta_fetched_key === eta_fetched_key) {
			return;
		}

		this.eta_fetched_key = eta_fetched_key;

		if (!this.state.eta_fetching)
			this.setState({ eta_fetching: true });

		const newState: State = { 
			eta_fetching: false,
		 };

		try {
			if (oc.due === "now") {
				const res = await store.api.order_eta({
					service: oc.service,
					due: oc.due,
					time: oc.time,
					date: oc.date,
					driving_time: oc.driving_time,
				});
				if (res.outcome === 0 && res.timestamp) {
					newState.eta_timestamp = res.timestamp;
					newState.eta_millis_to_customer = res.millis_to_customer;
				}
			}
		}
		catch (e) {
			logger.captureException(e);
		}
		finally {
			if(this._isMounted) this.setState(newState);
		}

	}

	renderEta() {
		const { store, t } = this.injected;

		const { eta_millis_to_customer } = this.state;
		if (!eta_millis_to_customer) {
			return null;
		}

		const minutesToCustomer = Math.round(eta_millis_to_customer / 1000 / 60);

		const text = store.order_config.s.service === "delivery" ?
			t("store.modals.order.config.eta.delivery", { minutesToCustomer }) :
			t("store.modals.order.config.eta.pickup", { minutesToCustomer });


		return <p className="m-b-5 lhp">{text}</p>;
	}

	render() {

		const { store, t } = this.injected;
		const { servicePaused, restaurant } = store;
		const operations = restaurant.settings.business.operations;
		const { service, due } = store.order_config.s;
		let closesIn = null;
		let closed = false;
		let expired = false;
		if (due === "now") {
			const ci = store.serviceClosesIn();
			closesIn = ci ? ci.minutes : null;
			closed = !store.serviceOpen();
		}
		else if (due === "later") {
			expired = store.order_config.expired();
		}

		const paused = servicePaused
			&& ((operations?.services || []).length === 0
				|| (operations?.services || []).includes(service)
			);

		const isLimit = store.order_config.getIsLimit();
		
		const canOrder = !expired && !closed && !paused && !isLimit;

		if (this.state.eta_fetching) {
			return (
				<div className="text-center m-t-6">
					<RotateLoader size={3} />
				</div>
			);
		}

		return (
			<div className="text-center m-t-6">
				{(closesIn !== null) && <p className="m-b-5 lhp error-text">{t("store.modals.checkout.warning.closing_soon", { value: closesIn })}</p>}
				{paused && <p className="m-b-5 lhp error-text">{t("store.modals.checkout.warning.paused")}</p>}
				{closed && <p className="m-b-5 lhp error-text">{t("store.modals.checkout.warning.closed")}</p>}
				{expired && <p className="m-b-5 lhp error-text">{t("store.modals.checkout.warning.expired")}</p>}
				{isLimit && <p className="m-b-5 lhp error-text">{store.order_config.s.due === 'later' ? t("store.modals.checkout.warning.expired"): t("store.modals.checkout.errors.exceeded_order_limit")}</p>}
				{this.renderEta()}
				<Button color="primary" className="width150" onClick={this.confirm} disabled={!canOrder}>
					{t("store.modals.order.config.start_button")}
				</Button>
			</div>
		);
	}

}

export const OrderConfigConfirmed = withTranslation()(OrderConfigConfirmedClass);
