import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Navigation } from './views/sections/nav';
import { NavigationBottom } from './views/sections/nav-bottom';
import { Header } from './views/sections/header';
import { Info } from './views/sections/info';
import { Footer } from './views/sections/footer';
import { OrderConfigModal } from './modals/order-config';
import { DishModal } from './modals/dish';
import { CartModal } from './modals/cart';
import { CheckoutModal } from './modals/checkout';
import { ReceiptModal } from './modals/receipt';
import { BookingReceiptModal } from './modals/booking_receipt';
import { AuthModal } from './modals/auth';
import { ResetPasswordModal } from './modals/reset-password';
import { CustomerModal } from './modals/customer';
import { OrderHistoryModal } from './modals/order-history';
import { MenuRestrictionsModal } from './modals/menu-restrictions';
import { CategoryRestrictionsModal } from './modals/category-restrictions';
import { OrderRestartModal } from './modals/order-restart';
import { LogoutWarningModal } from './modals/logout-warning';
import { OrderExpiredWarning } from './modals/order-expired-warning';
import { OrderExpiryWarning } from './modals/order-expiry-warning';
import { MapModal } from './modals/map';
import { PoliciesAndAgreementsModal } from './modals/terms';
import { ResellerPortalBackModal } from './modals/reseller-portal-back';
import { CustomPaymentModal } from './modals/custom-payment';
import { CoverLoader } from './components/CoverLoader';
import { MobxComponent } from '../mobx/component';
import { logger } from '@lib/common';
import { MenuSearchModal } from './components/menu_search/MenuSearchModal';
import { CardPaymentAuthenticationModal } from './modals/card-payment-authentication';
import { GkashFormModal } from './modals/payment/gkash';
import { SgepayFormModal } from './modals/payment/sgepay';
import { PayWayFormModal } from './modals/payment/payway';
import { SwishFormModal } from './modals/payment/swish';
import { FiservFormModal } from './modals/payment/fiserv';
import { VivaWalletFormModal } from './modals/payment/viva-wallet';
import { CabbagePayFormModal } from './modals/payment/cabbagepay';
import { PaymongoFormModal } from './modals/payment/paymongo';
import { ItemLayout } from './views/sections/item-layout';
import { CookieConsentBanner } from './components/cookie_consent';
import { FreeItemPromoUsageConfirmation } from './modals/cart/free-item-promo-usage-confirmation';
import { FreeItemsSelectionModal } from './modals/cart/free-items-selection';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import styled from 'styled-components';
import { AppRefreshModal } from './modals/app-refresh-modal';
import { BamboraNAFormModal } from './modals/payment/bambora-na';
import Cookies from 'js-cookie';
import * as Ably from "ably";
import { config } from "../../config";
import { PauseItems } from '../mobx/ably';

interface Props {}
interface State {}

const HiddenGoogleDiv = styled.div`
  display: none;
  visibility: hidden;
  height: 0px;
  opacity: 0;
`;

const ablyUpdateHandlers: { [key: string]: any } = {
  'tax-updated': (messageData: string, restaurantSettings: T.ObjectAny, res: any) => {
    if (messageData.includes('tax-updated')) {
      restaurantSettings.taxUpdate = res.restaurant.settings?.region?.tax;
    }
  },
};

@inject('store')
@observer
class AppClass extends MobxComponent<Props, State> {
  channel?: Ably.Types.RealtimeChannelCallbacks;

  state = {
    logged: this.injected.store.customer,
    store: this.injected.store,
  };

  componentDidCatch(error: Error) {
    logger.captureException(error);
  }
  handleCheckingOrder = async () => {
    const { store } = this.injected;
    const initial_order_id = store.order.getGeneratedOrderId();
    const orderChecking = await store.api.order_check({ _id: initial_order_id, isPostHookRequire: true});
    const { order, customer }: any = orderChecking;
    if(orderChecking && order && order._id === initial_order_id && order.status){
      if(order.status === "draft"){
        store.modal.isVisible('checkout');
      }else{
        await store.checkout.order_complete_v2({order: order, customer: customer});
      }
    }
  }
  async componentDidMount(): Promise<void> {
    const { store } = this.injected;
    // if (store.restaurant.settings.payments.stripe?.stripe_version === 'v2'){
    //   console.log("if condition");
    //   await this.handleCheckingOrder();
    // }
    this.ablyInit();
    window.addEventListener('online', async () => {
      await this.handleCheckingOrder();
    })
    if(localStorage.getItem('cw:stores_version') === null && 
    localStorage.getItem('cw:api_version') === null) {
      //no existing cookies yet, so fetch versions and update them once.. silently;
      const response = await store.api.getServiceLatestVersion();
      if (response.outcome === 0) {
        response.data.forEach((service: T.API.ServiceVersion) => {
          const key = `cw:${service.name}_version`;
          localStorage.setItem(key, service.latestVersion);
        });
      }
    }

    if (store.order_config.s.confirmed) {
      const generatedOrderId = store.order.getGeneratedOrderId();
      if (generatedOrderId === '' || generatedOrderId == null) {
        store.order.generateOrderId(true);
      } else {
        store.order.restartOpenReplay();
      }
    }

    if (!this.state.logged) {
      //disable auto select when logged out
      googleLogout();
    }

    if(!['cheerfy', 'cheerfy-test'].includes(store.reseller._id)) {
      window.onbeforeunload = function(e: any) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.returnValue = null;
      };
    }
  }
  // async componentDidUpdate(): Promise<void> {
  //   const getCustomerOrder = localStorage.getItem("save_customer_ordered")
  //   const customerOrder = getCustomerOrder ? JSON.parse(getCustomerOrder) : null;
  //   if(!!customerOrder){
  //     localStorage.removeItem("save_customer_ordered")
  //     await this.handleCheckingOrder()
  //   }
  // }

  componentWillUnmount(): void {
    if (this.channel) {
      this.channel.unsubscribe()
    }
  }

  ablyInit = () => {
    const { store } = this.injected;
    const restaurantID = store.restaurant._id;
    const ablyInstance = new Ably.Realtime(config.ably_order_updates_key);
    this.channel = ablyInstance.channels.get(`public:restaurant-updates:${restaurantID}`);
    this.channel.subscribe(this.ablyHandleSubscription);
	}

	ablyHandleSubscription = (message: Ably.Types.Message) => {
		const { store, t } = this.injected;
    switch (message.name) {
      case "restaurant:operations:updated":
        const pausedServicesData = message.data as T.Schema.Restaurant.RestaurantOperations;
        store.ably.update({
          pausedServices: pausedServicesData
        })
        break;
      case "restaurant:menus:operations:updated":
        const pausedItemsData = message.data as PauseItems;
        store.ably.update({
          pausedItems: pausedItemsData
        })
        break;
      case "restaurant:settings:updated":
        store.api.getRestaurant({_id: store.restaurant._id}).then(res => {
          if (res.outcome) return
          let restaurantSettings: T.ObjectAny = {};
          Object.keys(ablyUpdateHandlers).forEach(update => ablyUpdateHandlers[update](message.data, restaurantSettings, res));
          if (Object.keys(restaurantSettings).length > 0) {
            store.ably.update({ restaurantSettings });
            store.updateRestaurant(res.restaurant);
          }
        })
        break;
      default:
        break;
    }

		// if (message.name === "restaurant:operations:updated") {
		// 	const data = message.data as T.Schema.Restaurant.RestaurantOperations;
    //   store.ably.update({
    //     pausedServices: data
    //   })
		// }else if (message.name === "restaurant:menus:operations:updated") {
    //   const data = message.data as PauseItems;
    //   store.ably.update({
    //     pausedItems: data
    //   })
    // } else if (message.name === 'restaurant:settings:updated') {
    //   let restaurantSettings: any
    //   store.api.getRestaurant({_id: store.restaurant._id}).then(res => {
    //     if (res.outcome) return
    //     if (message.data.includes('tax-updated')) {
    //       restaurantSettings = {
    //         ...restaurantSettings,
    //         taxUpdate: res.restaurant.settings?.region?.tax
    //       };
    //     }
    //     store.ably.update({restaurantSettings: restaurantSettings});
    //     store.updateRestaurant(res.restaurant);
    //   })
    // }
	}

  render() {
    const { store } = this.injected;
    const servicesAllDisabled = store.servicesAllDisabled;
    const orderingDisabled = store.orderingDisabled;
    const oc = store.order_config.s;
    const { isLoggedIn } = store.customer;
    const { logged_in_only, service_required } = store.restaurant.settings.business;
    const isPaymentForm = store.router.s.path === '/online-payment';
    const r = this.injected.store.restaurant;
    const paddingTop = this.injected.store.view.s.top_nav_height;
    const { images } = r.website.sections.header;
    let blur = false;
    if ((logged_in_only && !isLoggedIn) || (service_required && !oc.confirmed)) blur = true;
    if (service_required && (servicesAllDisabled || orderingDisabled)) blur = false;

    if (isPaymentForm) {
      return (
        <main>
          <CustomPaymentModal />
        </main>
      );
    }

    return (
      <main>
        {!isLoggedIn && (r.settings.accounts.google.api_client_id ||  (['cloudwaitress', 'cloudwaitress-test'].includes(store.reseller._id)))? (
          <HiddenGoogleDiv>
            <GoogleLogin
              onSuccess={credentialResponse => {
                if (credentialResponse) {
                  store.api
                    .customerLoginNewGoogle({
                      clientId: credentialResponse?.clientId || '',
                      credential: credentialResponse?.credential || '',
                    })
                    .then(response => {
                      if (response.outcome == 0) {
                        store.customer.handleSocialLogin(response.customer);
                      } else {
                        throw 'Login failed';
                      }
                    });
                } else throw 'Login failed';
              }}
              onError={() => {
                throw 'Login Failed';
              }}
              useOneTap
            />
          </HiddenGoogleDiv>
        ) : null}

        <Navigation />
        <NavigationBottom />
        <div
          id="scroll-root"
          style={
            !blur
              ? undefined
              : {
                  filter: 'blur(5px)',
                  transform: 'scale(1.01)',
                }
          }
        >
          {typeof images.show === 'undefined' || images.show ? <Header /> : <div style={{ paddingTop }}></div>}
          <ItemLayout />
          <Info />
          <Footer />
        </div>
        <MenuSearchModal />
        <OrderConfigModal />
        <OrderRestartModal />
        <CartModal />
        <CheckoutModal />
        <ReceiptModal />
        <BookingReceiptModal />
        <DishModal />
        <AuthModal />
        <ResetPasswordModal />
        <CustomerModal />
        <OrderHistoryModal />
        <MenuRestrictionsModal />
        <CategoryRestrictionsModal />
        <LogoutWarningModal />
        <OrderExpiredWarning />
        <OrderExpiryWarning />
        <MapModal />
        <ResellerPortalBackModal />
        <PoliciesAndAgreementsModal />
        <CookieConsentBanner />
        <CoverLoader />
        <CardPaymentAuthenticationModal />
        <GkashFormModal />
        <SgepayFormModal />
        <PayWayFormModal />
        <SwishFormModal />
        <FiservFormModal />
        <VivaWalletFormModal />
        <CabbagePayFormModal />
        <PaymongoFormModal />
        <BamboraNAFormModal />
        <FreeItemPromoUsageConfirmation />
        <FreeItemsSelectionModal />
        <AppRefreshModal />
      </main>
    );
  }
}

export const App = hot(AppClass);
