import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../mobx/component';
import { Checkbox, Input, SelectAdv, shadeAlternate, styled } from '@lib/components';
import _toString from 'lodash/toString';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import { IconPickerItem, IconList } from 'react-fa-icon-picker';

const CheckoutFieldWrapper = styled.div`
  margin: auto;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  background: ${({ theme }) => shadeAlternate(0.04, theme.box.background)};
  color: ${({ theme }) => theme.box.text};
  .icon {
    font-size: 30px;
  }
`;
interface CheckoutFieldProps {
  field: T.Schema.Restaurant.Services.CustomCheckoutField;
}
interface CheckoutFieldState {
  checkoutState: boolean;
  inputText: string;
  dropdownValue: string;
  dropdownSelection: string;
}
interface DropdownItem {
  label: string;
  value: string;
}

@inject('store')
@observer
export class CustomCheckoutField extends MobxComponent<CheckoutFieldProps, CheckoutFieldState> {
  dropdownOptions: Array<DropdownItem>;
  constructor(props: CheckoutFieldProps) {
    super(props);
    const { store } = this.injected;
    const checkoutFieldList = store.checkout.s.checkout_fields;
    const checkoutField = _find(checkoutFieldList, field => field.id === this.props.field._id);
    const answer = checkoutField ? checkoutField.answer : '';

    const field = this.props.field;
    let options = [{ value: '', label: '' }];
    if (field.dropdown_options && field.dropdown_options.length > 0) {
      options = field.dropdown_options.map((field: string, index: number) => ({
        value: index.toString(),
        label: field.toString(),
      }));
    }

    this.dropdownOptions = options;

    const type = this.props.field.type;

    this.state = {
      checkoutState: type === 'checkbox' ? answer === 'yes' : false,
      inputText: type === 'input' ? answer : '',
      dropdownValue: type === 'dropdown' ? this.findDropdownValue(answer) : '',
      dropdownSelection: '',
    };
  }

  findDropdownLabel = (value: string) => {
    const item = _find(this.dropdownOptions, item => item.value === value);
    return item ? item.label : '';
  };

  findDropdownValue = (label: string) => {
    const item = _find(this.dropdownOptions, item => item.label === label);
    return item ? item.value : '';
  };

  handleCheckoutChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checkoutState: e.target.checked }, this.updateFieldState);
  };

  handleDropdownChanged = (value: string) => {
    this.setState(
      {
        dropdownValue: value,
        dropdownSelection: this.findDropdownLabel(value),
      },
      this.updateFieldState
    );
  };

  handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputText: e.target.value }, this.updateFieldState);
  };

  computeFieldAnswer(field: T.Schema.Restaurant.Services.CustomCheckoutField) {
    switch (field.type) {
      case 'input':
        return this.state.inputText;
      case 'dropdown':
        return this.state.dropdownSelection;
      case 'checkbox':
        return this.state.checkoutState ? 'yes' : 'no';
      default:
        return '';
    }
  }

  componentDidMount() {
    this.updateFieldState();
  }

  updateFieldState() {
    const { store } = this.injected;
    const field = this.props.field;
    const checkoutFieldList = store.checkout.s.checkout_fields;
    const index = _findIndex(checkoutFieldList, field => field.id === this.props.field._id);
    if (index === -1) {
      return store.checkout.update({
        error: '',
        checkout_fields: [
          ...checkoutFieldList,
          {
            id: field._id,
            question: {
              label: _toString(field.label),
              description: _toString(field.description),
            },
            answer: this.computeFieldAnswer(field),
            type: field.type,
          },
        ],
      });
    }

    let currentCheckoutField = checkoutFieldList[index];
    currentCheckoutField.answer = this.computeFieldAnswer(field);
    checkoutFieldList[index] = currentCheckoutField;
    store.checkout.update({ error: '', checkout_fields: [...checkoutFieldList] });
  }

  render() {
    const { field } = this.props;

    return (
      <CheckoutFieldWrapper className={this.injected.store.checkout.s.error === 'missing_custom_checkout_fields' ? 'border-error': ''}>
        <div className="flex-left m-t-2 p-4 cursor">
          <div className="m-r-4">
            <IconPickerItem icon={field.icon as IconList} size={20} />
          </div>
          <div className="m-r-3">
            <p className="font-semi-bold lhp">
              {field.label}
              {field.required && (
                <span
                  style={{
                    color: 'red',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  *
                </span>
              )}
            </p>
            <p className="small lhp">{field.description}</p>
          </div>

          {field.type === 'checkbox' && (
            <div className="flex-margin-left flex-wrap">
              <Checkbox
                id={`mockup-checkbox-${field._id}`}
                name="mockup-checkbox"
                checked={this.state.checkoutState}
                onChange={this.handleCheckoutChanged}
              />
            </div>
          )}
        </div>

        {field.type === 'dropdown' && (
          <div className="p-t-0 p-b-3 p-l-3 p-r-3">
            <SelectAdv
              type="single"
              options={this.dropdownOptions}
              value={this.state.dropdownValue}
              onChange={this.handleDropdownChanged}
            />
          </div>
        )}

        {field.type === 'input' && (
          <div className="p-t-0 p-b-3 p-l-3 p-r-3">
            <Input
              disabled={this.injected.store.checkout.s.loading}
              type="text" value={this.state.inputText} onChange={this.handleInputChanged} />
          </div>
        )}
      </CheckoutFieldWrapper>
    );
  }
}
